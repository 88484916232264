import React from 'react';

import styled from '@emotion/styled';

type Size = 'small' | 'medium';

const TimeToRead: React.FC<{
  time: number;
  size?: Size;
}> = ({ time, size = 'medium' }) => (
  <Wrapper size={size}>
    <div className="icon-wrapper">
      <svg
        className="timetoread-icon"
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
        <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
      </svg>
    </div>
    {`${time}分`}
  </Wrapper>
);

export default TimeToRead;

const Wrapper = styled.div<{ size: Size }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: ${(props) => {
    if (props.size === 'small') return '12px';
    else return 'inherit';
  }};

  .icon-wrapper {
    line-height: 0;
    margin-right: 4px;

    .timetoread-icon {
      width: ${(props) => {
        if (props.size === 'small') return '12px';
        else return '16px';
      }};
      height: ${(props) => {
        if (props.size === 'small') return '12px';
        else return '16px';
      }};
      fill: ${(props) => props.theme.colors.light};
    }
  }
`;
